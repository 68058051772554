<template>
  <el-input
    v-bind="$attrs"
    :data-testid="testid"
    clearable
    :placeholder="placeholder || $t('common.input.placeholder')"
    v-on="$listeners"
    @wheel.native.prevent
  >
    <!-- 遍历子组件非作用域插槽，并对父组件暴露 -->
    <template v-for="(index, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </el-input>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  testName: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
  },
})

const testidRoot = 'el-input'

const testid = computed(() => {
  return props.testName ? `${testidRoot}-${props.testName}` : testidRoot
})

</script>

<style scoped lang="postcss">
::v-deep .el-input__suffix {
  @apply right-[12px];
}
</style>
