const ls = require('local-storage')

const Temp = 'Temp'
const Token = 'Token'
const User = 'User'
const Shop = 'Shop'
const StoreAccount = 'StoreAccount'
const SidebarPage = 'SidebarPage'
const ActiveTab = 'ActiveTab'
const NeedTransfer = 'NeedTransfer'

export const KEY_MEMBER_TAG_FILTER = '$bob_dash_member_tag_filter'
export const KEY_MEMBER_TAG_PUSH_MESSAGE = '$bob_dash_member_tag_push_msg'

export default {
  set (key, value) {
    return ls.set(key, value)
  },
  get (key) {
    return ls.get(key)
  },
  remove (key) {
    return ls.remove(key)
  },
  // ---- Token
  setToken (token) {
    ls.set(Token, token)
  },
  getToken () {
    return ls.get(Token)
  },

  // ---- User
  setUser (user) {
    ls.set(User, user)
  },
  getUser () {
    return ls.get(User)
  },

  // ---- Shop
  setShop (shop) {
    ls.set(Shop, shop)
  },
  getShop () {
    return ls.get(Shop)
  },

  // ---- Temp
  setTemp (object) {
    ls.set(Temp, object)
  },
  getTemp () {
    return ls.get(Temp)
  },
  removeTemp () {
    return ls.remove(Temp)
  },

  // ---- StoreAccount
  setStoreAccount (list) {
    ls.set(StoreAccount, list)
  },
  getStoreAccount () {
    return ls.get(StoreAccount)
  },

  // ---- SidebarPage
  setSidebarPage (page) {
    ls.set(SidebarPage, page)
  },
  getSidebarPage () {
    return ls.get(SidebarPage)
  },

  // ---- 會員個人頁 tabs 選擇
  setActiveTab (tag) {
    ls.set(ActiveTab, tag)
  },
  getActiveTab () {
    return ls.get(ActiveTab)
  },

  getNeedTransfer () {
    return ls.get(NeedTransfer)
  },
  removeNeedTransfer () {
    return ls.remove(NeedTransfer)
  },
}
