import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
import { get, isArray } from 'lodash'
import Cookies from 'js-cookie'
// import en from '@/locales/en.json'
// import zh from '@/locales/zh.json'
// import jp from '@/locales/jp.json'

import axios from 'axios'
// i18n

Vue.use(VueI18n)

let browserLanguage = Cookies.get('old_locale') || window.navigator.language
// console.log('browserLanguage', Cookies.get('old_locale'), browserLanguage)
// 先塞入瀏覽器預設語言在 i18n
if (browserLanguage.toLowerCase() === 'zh-tw') {
  browserLanguage = 'zh'
} else if (browserLanguage.toLowerCase() === 'ja-jp' || browserLanguage.toLowerCase() === 'ja') {
  browserLanguage = 'jp'
} else if (browserLanguage.toLowerCase() === 'kr') {
  browserLanguage = 'kr'
} else {
  browserLanguage = 'en'
}

// 存放語系 json
// const messages = {
//   en: en,
//   zh: zh,
//   jp: jp,
// }

export const i18n = new VueI18n({
  locale: browserLanguage, // set locale
  fallbackLocale: browserLanguage,
  messages: {}, // set locale messages
  silentTranslationWarn: true,
})

export const t = (key, ...params) => i18n.t(key, params)

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  Cookies.set('old_locale', lang)
  // console.log('set cookie', lang, Cookies.get('old_locale'))
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}
function loadLanguageFile (path, lang) {
  return import(/* webpackChunkName: "[request]-lang-[request]" */ `@/locales/${path}/${lang}.json`)
    .then(messages => {
      const currentMessages = i18n.getLocaleMessage(lang) || {}
      i18n.setLocaleMessage(lang, {
        ...currentMessages,
        ...messages.default,
      })
    })
    .catch(error => {
      console.warn(`Language file not found for ${path} in ${lang}:`, error)
    })
}
export function loadLanguageAsync (lang) {
  // 如果語言已經是目前的語言 , 如果語言已經被載入過
  if (i18n.locale.includes(lang) || loadedLanguages.includes(lang)) {
    Promise.resolve(setI18nLanguage(lang))
  }

  // 預設載入 common/ 和 parameters/ 作為主語言檔
  // 直接載入所有 for development
  const languagePromises = [
    loadLanguageFile('common', lang),
    loadLanguageFile('basic', lang),
    loadLanguageFile('parameters', lang),
    loadLanguageFile('publicAppointment', lang),
    loadLanguageFile('reservations', lang),
    loadLanguageFile('sheetExportCenter', lang),
    loadLanguageFile('org', lang),
    loadLanguageFile('login', lang),
    loadLanguageFile('memberStore', lang),
  ]

  // 如果有指定的模組，載入對應模組的語言檔
  // const currentModule = get(store, 'state.app.currentModule');
  // // console.log('!!!currentModule', currentModule);
  // // Load language files for each module in store.currentModule (if defined and an array)
  // if (isArray(currentModule)) {
  //   currentModule.forEach(module => {
  //     // console.log('!!!module', module);
  //     const moduleImport = import(/* webpackChunkName: "module-lang-[request]" */ `@/locales/${module}/${lang}.json`)
  //       .then(moduleMessages => {
  //         // console.log(`Imported module file for ${module}:`, lang, moduleMessages.default);
  //         const currentMessages = i18n.getLocaleMessage(lang) || {};
  //         i18n.setLocaleMessage(lang, {
  //           ...currentMessages,
  //           ...moduleMessages.default,
  //         });
  //       })
  //       .catch(error => {
  //         console.warn(`Module-specific language file not found for ${module} in ${lang}:`, error);
  //       });
  //       languagePromises.push(moduleImport);
  //     // console.log('!!!languagePromises', languagePromises);
  //   });
  // }

  // 等待所有檔案載入完成後，設定語言
  return Promise.all(languagePromises)
    .then(() => {
      if (!loadedLanguages.includes(lang)) {
        loadedLanguages.push(lang)
      }
      return setI18nLanguage(lang)
    })
}
