import { computed } from 'vue'
import store from '@/store'
import { checkUserFeature, checkFeature } from '@/store/modules/permission'
import { get, some, isArray } from 'lodash'
import configuration from '@/configuration'
import { resetRouter } from '@/router'
import { useRouter } from 'vue-router/composables'

export const usePermissions = () => {
  const router = useRouter()
  const originShopPlanFeatures = computed(() => get(store.state, 'permission.raw.shopPlanFeatures'))
  const originShopUserFeatures = computed(() => get(store.state, 'permission.raw.userFeatures'))
  const originShopPlanLimits = computed(() => get(store.state, 'permission.raw.shopPlanLimits'))
  const computedShopPlanFeatures = computed(() => get(store.state, 'permission.computed.shopPlanFeatures'))
  const computedShopUserFeatures = computed(() => get(store.state, 'permission.computed.userFeatures'))
  const computedShopPlanLimits = computed(() => get(store.state, 'permission.computed.shopPlanLimits'))

  const inUseShopPlanFeatures = computed(() => {
    if (configuration('featureKeyDebugger')) return computedShopPlanFeatures.value
    return originShopPlanFeatures.value
  })
  const inUseShopUserFeatures = computed(() => {
    if (configuration('featureKeyDebugger')) return computedShopUserFeatures.value
    return originShopUserFeatures.value
  })

  const checkAction = (action) => {
    if (!action) return false
    if (configuration('featureKeyDebugger')) {
      return checkUserFeature(computedShopPlanFeatures.value, computedShopUserFeatures.value, action)
    }
    return checkUserFeature(originShopPlanFeatures.value, originShopUserFeatures.value, action)
  }

  const checkActionList = (actionList, validType = 'union') => {
    if (!actionList || !actionList.length) return false
    const result = []
    let shopFeatures = originShopPlanFeatures.value
    let userFatures = originShopUserFeatures.value
    if (configuration('featureKeyDebugger')) {
      shopFeatures = computedShopPlanFeatures.value
      userFatures = computedShopUserFeatures.value
    }
    actionList.forEach(action => {
      result.push(checkUserFeature(shopFeatures, userFatures, action))
    })
    if (validType === 'union') {
      if (some(result, res => res === false)) return false
      return true
    }
    if (validType === 'intersection') {
      if (some(result, res => res === true)) return true
      return false
    }
  }

  const refreshRoutes = async (origin = false) => {
    resetRouter()
    let userFeaturesSource = inUseShopUserFeatures.value
    let shopFeaturesSource = inUseShopPlanFeatures.value
    if (origin) {
      userFeaturesSource = originShopUserFeatures.value
      shopFeaturesSource = originShopPlanFeatures.value
    }

    const accessRoutes = await store.dispatch(
      'permission/generateRoutes',
      {
        features: userFeaturesSource,
        shopPlan: { shopPlanFeature: shopFeaturesSource },
      })
    accessRoutes.forEach((item) => {
      router.addRoute(item)
    })
  }

  const checkActionByUserFeatures = (action) => {
    if (!action) return false
    if (configuration('featureKeyDebugger')) {
      return checkFeature(computedShopUserFeatures.value, action)
    }
    return checkFeature(originShopUserFeatures.value, action)
  }

  return {
    checkAction,
    originShopPlanFeatures,
    originShopUserFeatures,
    originShopPlanLimits,
    computedShopPlanFeatures,
    computedShopUserFeatures,
    computedShopPlanLimits,
    inUseShopPlanFeatures,
    inUseShopUserFeatures,
    refreshRoutes,
    checkActionList,
    checkActionByUserFeatures,
  }
}
