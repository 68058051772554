import { admin, admin2 } from '@/api/instance'

const config = (shopId) => {
  return `/${shopId}/appointmentConfig`
}

const reservation = (shopId) => {
  return `/${shopId}/appointmentReservation`
}

//= > 預約參數設置
export const UpdateReservationParameter = async ({
  shopId,
  timeUnit,
  timeUnitOffset,
  minReservationInterval,
  maxReservationInterval,
  cancelInterval,
  useNotSpecify,
  notSpecifySkipUnit,
  notSpecifyImage,
  notSpecifyName,
  notSpecifyRule,
  notSpecifyUnits,
  pageStyle,
  pageText,
  allowOrderMorePeople,
  maxOrderMorePeopleCount,
  allowOtherReservation,
  secondOrderStartClientChange,
  secondOrderStartDefault,
  orderServiceAttachClientCheck,
  orderServiceAttachClientNullOption,
  orderServiceAttachClientNullOptionPosition,
  orderServiceAttachClientMultipleSelection,
  enableUserAddress,
  notSpecifyOrderRule,
  chargeType,
  allowBatchOrder,
  clientOrderMode,
  batchOrderNotifyMode,
}) => {
  return await admin2({
    method: 'PUT',
    url: `${config(shopId)}`,
    data: {
      timeUnit,
      timeUnitOffset,
      minReservationInterval,
      maxReservationInterval,
      cancelInterval,
      useNotSpecify,
      notSpecifySkipUnit,
      notSpecifyImage,
      notSpecifyName,
      notSpecifyRule,
      notSpecifyUnits,
      pageStyle,
      pageText,
      allowOrderMorePeople,
      maxOrderMorePeopleCount,
      allowOtherReservation,
      secondOrderStartClientChange,
      secondOrderStartDefault,
      orderServiceAttachClientCheck,
      orderServiceAttachClientNullOption,
      orderServiceAttachClientNullOptionPosition,
      orderServiceAttachClientMultipleSelection,
      enableUserAddress,
      notSpecifyOrderRule,
      chargeType,
      allowBatchOrder,
      clientOrderMode,
      batchOrderNotifyMode,
    },
  })
}

//= > 查找店家預約參數
export const FindReservationParameter = async ({ shopId }) => {
  const res = await admin({
    method: 'get',
    url: `${config(shopId)}`,
  })

  return res.data
}

//= > 預約創建
export const CreateReservation = async ({
  shopId,
  appointmentUnitId,
  appointmentServiceId,
  appointmentServiceAttachIds,
  start,
  totalBookingTime,
  userName,
  userPhone,
  userComment,
  remark,
  memberId,
  overtime,
}) => {
  const res = await admin({
    method: 'post',
    url: `${reservation(shopId)}`,
    data: {
      appointmentUnitId,
      appointmentServiceId,
      appointmentServiceAttachIds,
      start,
      totalBookingTime,
      userName,
      userPhone,
      userComment,
      remark,
      memberId,
      overtime,
    },
  })

  return res.data
}

//= > 取得預約
export const GetReservation = async ({
  shopId,
  dateStart,
  dateEnd,
  start,
  limit,
  sort,
  sortBy,
  code,
  userName,
  MemberId,
  AppointmentUnitId,
  AppointmentServiceId,
  ResourceItemId,
  resourceItemCategoryIds,
  includeAppointmentOrderCheckIn,
  includeAppointmentReservationTicket,
  includeCustomFlowRecord,
  includeAppointmentOrderDeposit,
  includeAppointmentCheckoutOrder,
  customFlowNodeId,
}) => {
  const res = await admin({
    method: 'get',
    url: `${reservation(shopId)}`,
    params: {
      dateStart,
      dateEnd,
      start,
      limit,
      sort,
      sortBy,
      code,
      userName,
      MemberId,
      AppointmentUnitId,
      AppointmentServiceId,
      ResourceItemId,
      resourceItemCategoryIds,
      includeAppointmentOrderCheckIn,
      includeAppointmentReservationTicket,
      includeCustomFlowRecord,
      includeAppointmentOrderDeposit,
      includeAppointmentCheckoutOrder,
      customFlowNodeId,
    },
  })

  return res.data
}

export const GetReservationSimple = async ({
  limit,
  start,
  sort,
  sortBy,
  shopId,
  userName,
  ResourceItemId,
  resourceItemCategoryIds,
  dateStart,
  dateEnd,
  AppointmentUnitId,
  AppointmentServiceId,
  code,
}) => {
  return await admin2({
    url: `${reservation(shopId)}/simpleFind`,
    params: {
      limit,
      start,
      sort,
      sortBy,
      userName,
      ResourceItemId,
      resourceItemCategoryIds,
      dateStart,
      dateEnd,
      AppointmentUnitId,
      AppointmentServiceId,
      code,
    },
  })
}

//= > 取得預約總數
export const GetReservationCount = async ({
  shopId,
  dateStart,
  dateEnd,
  code,
  userName,
  MemberId,
  AppointmentUnitId,
  AppointmentServiceId,
  ResourceItemId,
  resourceItemCategoryIds,
  customFlowNodeId,
}) => {
  const res = await admin({
    method: 'get',
    url: `${reservation(shopId)}/count`,
    params: {
      dateStart,
      dateEnd,
      code,
      userName,
      MemberId,
      AppointmentUnitId,
      AppointmentServiceId,
      ResourceItemId,
      resourceItemCategoryIds,
      customFlowNodeId,
    },
  })

  return res.data.count
}

//= > 查找預約
export const FindReservation = async ({
  shopId,
  id,
  includeCustomFlowRecord,
  includeAppointmentVisitorPreOrder,
}) => {
  const res = await admin({
    method: 'get',
    url: `${reservation(shopId)}/${id}`,
    params: {
      includeCustomFlowRecord,
      includeAppointmentVisitorPreOrder,
    },
  })

  return res.data
}

//= > 更新預約資訊
export const UpdateReservationInfo = async ({
  shopId,
  id,
  userName,
  userPhone,
  userComment,
  remark,
}) => {
  return await admin2({
    method: 'put',
    url: `${reservation(shopId)}/${id}/info`,
    data: {
      userName,
      userPhone,
      userComment,
      remark,
    },
  })
}

//= > 更新預約時間與服務
export const UpdateReservationTimeServices = async ({
  shopId,
  id,
  start,
  resourceItemId,
  appointmentUnitId,
  appointmentServiceId,
  appointmentSubServiceId,
  appointmentServiceAttachIds,
  totalBookingTime,
  resourceItemTotalBookingTime,
  overtime,
}) => {
  return await admin2({
    method: 'PUT',
    url: `${reservation(shopId)}/${id}/time`,
    data: {
      start,
      resourceItemId,
      appointmentUnitId,
      appointmentServiceId,
      appointmentSubServiceId,
      appointmentServiceAttachIds,
      totalBookingTime,
      resourceItemTotalBookingTime,
      overtime,
    },
  })
}

//= > 更新預約狀態
export const UpdateReservationStatus = async ({ shopId, id, status }) => {
  const res = await admin({
    method: 'put',
    url: `${reservation(shopId)}/${id}/status`,
    data: {
      status,
    },
  })

  return res.data
}

//= > 取得預約次數，可過濾狀態
/**
 *  reservationStatus: {
 *    已預約: 'wait',
 *    完成: 'complete',
 *    未到: 'absence',
 *    取消: 'cancel',
 *  },
 */
export const GetReservationCountByStatus = async ({ shopId, MemberId, status }) => {
  const res = await admin({
    method: 'GET',
    url: `${reservation(shopId)}/count`,
    params: {
      MemberId,
      status,
    },
  })
  return res.data
}

//  Reservation Order 預約訂單 NEW
export const CreateReservationOrder = async ({
  shopId,
  reservations,
  userType,
  memberId,
  userName,
  userPhone,
  userComment,
  adminComment,
  peopleCount,
  depositPrice,
  depositPaidType,
  depositPaidTypeComment,
  // remark,
}) => {
  const res = await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder`,
    data: {
      reservations,
      userType,
      memberId,
      userName,
      userPhone,
      peopleCount,
      userComment,
      adminComment,
      depositPrice,
      depositPaidType,
      depositPaidTypeComment,
      // remark,
    },
  })

  return res
}

//  Reservation Order smartCreate
export const SmartCreateReservationOrder = async ({
  shopId,
  reservations,
  userType,
  memberId,
  userName,
  userPhone,
  userComment,
  adminComment,
  peopleCount,
  depositPrice,
  depositPaidType,
  depositPaidTypeComment,
  checkoutPrice,
  checkoutPaidType,
  checkoutPaidTypeComment,
  checkoutInvoiceInfo,
  cashbackDeductionAmount,
}) => {
  const res = await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder/smartCreate`,
    data: {
      reservations,
      userType,
      memberId,
      userName,
      userPhone,
      peopleCount,
      userComment,
      adminComment,
      depositPrice,
      depositPaidType,
      depositPaidTypeComment,
      checkoutPrice,
      checkoutPaidType,
      checkoutPaidTypeComment,
      checkoutInvoiceInfo,
      cashbackDeductionAmount,
    },
  })

  return res
}

export const GetReservationOrder = async ({ shopId, start, limit, dateStart, dateEnd }) => {
  const res = await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentOrder`,
    params: {
      start,
      limit,
      dateStart,
      dateEnd,
    },
  })

  return res
}

export const GetReservationOrderCount = async ({
  shopId,
  MemberId,
  dateStart,
  dateEnd,
  code,
  userName,
  AppointmentUnitId,
  AppointmentServiceId,
}) => {
  const res = await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentOrder/count`,
    params: {
      MemberId,
      dateStart,
      dateEnd,
      code,
      userName,
      AppointmentUnitId,
      AppointmentServiceId,
    },
  })

  return res
}

export const FindReservationOrder = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentOrder/${id}`,
  })

  return res
}

export const UpdateReservationOrderStatus = async ({ shopId, id, status }) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentOrder/${id}/status`,
    data: {
      status,
    },
  })
}

export const UpdateReservationOrderInfo = async ({
  shopId,
  id,
  userName,
  userPhone,
  userComment,
  userAddress,
  adminComment,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentOrder/${id}/info`,
    data: {
      userName,
      userPhone,
      userComment,
      userAddress,
      adminComment,
    },
  })
}

export const OrderDepositRefund = async ({
  shopId,
  id,
  refundType,
  refundTypeComment,
  refundPrice,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder/${id}/depositRefund`,
    data: {
      refundType,
      refundTypeComment,
      refundPrice,
    },
  })
}

export const OrderCheckoutRefund = async ({
  shopId,
  id,
  refundType,
  refundTypeComment,
  refundPrice,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder/${id}/checkoutRefund`,
    data: {
      refundType,
      refundTypeComment,
      refundPrice,
    },
  })
}

export const UpdateAppointmentHomePage = async ({ shopId, id, enable, title, content, images }) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentHomePage`,
    data: {
      enable,
      title,
      content,
      images,
    },
  })
}

export const GetAppointmentHomePage = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentHomePage`,
  })
}

export const UpdateAppointmentCreatePromptPage = async ({ shopId, enable, title, content }) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentCreatePromptPage`,
    data: {
      enable,
      title,
      content,
    },
  })
}

export const GetAppointmentCreatePromptPage = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentCreatePromptPage`,
  })
}

export const GetAvailableTimes = async ({
  shopId,
  rangeStart,
  rangeEnd,
  appointmentUnitIds,
  resourceItemIds,
  appointmentServiceId,
  appointmentSubServiceId,
  appointmentServiceAttachIds,
  peopleCount,
  preReservations,
  omitOhbookBookingIds,
  setTotalBookingTime,
  setResourceItemTotalBookingTime,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder/availableTimes`,
    data: {
      rangeStart,
      rangeEnd,
      appointmentUnitIds,
      resourceItemIds,
      appointmentServiceId,
      appointmentSubServiceId,
      appointmentServiceAttachIds,
      peopleCount,
      preReservations,
      omitOhbookBookingIds,
      setTotalBookingTime,
      setResourceItemTotalBookingTime,
    },
  })
}

export const CheckReservation = async ({
  shopId,
  start,
  resourceItemIds,
  appointmentUnitIds,
  appointmentServiceId,
  appointmentSubServiceId,
  appointmentServiceAttachIds,
  useNotSpecifyRule,
  peopleCount,
  preReservations,
  omitOhbookBookingIds,
  setTotalBookingTime,
  setResourceItemTotalBookingTime,
  isOvertime,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder/preDetailByStart`,
    data: {
      start,
      resourceItemIds,
      appointmentUnitIds,
      appointmentServiceId,
      appointmentSubServiceId,
      appointmentServiceAttachIds,
      useNotSpecifyRule,
      peopleCount,
      preReservations,
      omitOhbookBookingIds,
      setTotalBookingTime,
      setResourceItemTotalBookingTime,
      isOvertime,
    },
  })
}

export const FindReservationOrderHistory = async ({ shopId, orderId, start, limit }) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/appointmentOrder/${orderId}/events`,
    params: {
      start,
      limit,
    },
  })
}

// checkout 預約結帳 api

export const GetReservationCheckoutConfig = async ({ shopId }) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/appointmentCheckoutConfig`,
  })
}

// update checkout config
export const UpdateReservationCheckoutConfig = async ({
  shopId, freeMemberConfig,
  enableCashbackDeduction,
  cashbackDeductionPercent,
  enableServiceAttachCashbackDeduction,
  paymentDeadlineMinute,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentCheckoutConfig`,
    data: {
      freeMemberConfig,
      enableCashbackDeduction,
      cashbackDeductionPercent,
      enableServiceAttachCashbackDeduction,
      paymentDeadlineMinute,
    },
  })
}

export const GetReservationCheckoutPaymentConfig = async ({ shopId, start, limit }) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/appointmentCheckoutPaymentConfig`,
    params: {
      start,
      limit,
    },
  })
}

// get config by count
export const GetReservationCheckoutPaymentConfigCount = async ({ shopId }) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/appointmentCheckoutPaymentConfig/count`,
  })
}

// find payment by id
export const FindReservationCheckoutPaymentConfig = async ({ shopId, id }) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/appointmentCheckoutPaymentConfig/${id}`,
  })
}

// create payment
export const CreateReservationCheckoutPaymentConfig = async ({ shopId, type, enable, config }) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/appointmentCheckoutPaymentConfig`,
    data: {
      type,
      enable,
      config,
    },
  })
}

// delete payment by id
export const DeleteReservationCheckoutPaymentConfig = async ({ shopId, id }) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/appointmentCheckoutPaymentConfig/${id}`,
  })
}

// update payment by id
export const UpdateReservationCheckoutPaymentConfig = async ({ shopId, id, enable }) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentCheckoutPaymentConfig/${id}`,
    data: {
      enable,
    },
  })
}

// get checkout invoice config
export const GetReservationCheckoutInvoiceConfig = async ({ shopId }) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/appointmentCheckoutInvoiceConfig`,
  })
}

// update checkout invoice config
export const UpdateReservationCheckoutInvoiceConfig = async ({
  shopId,
  enable,
  platform,
  invoiceApiConfig,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentCheckoutInvoiceConfig`,
    data: {
      enable,
      platform,
      invoiceApiConfig,
    },
  })
}

export const UpdateReservationCheckoutInvoiceStatus = async ({ shopId, id, invoiceStatus }) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentCheckoutOrder/${id}/invoiceStatus`,
    data: {
      invoiceStatus,
    },
  })
}

export const ReservationOrderCalculateCheckoutPrice = async ({
  shopId,
  memberId,
  reservations,
  cashbackDeductionAmount,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder/calculateCheckoutPrice`,
    data: {
      memberId,
      reservations,
      cashbackDeductionAmount,
    },
  })
}

export const CalculateCheckoutCashbackLimit = async ({
  memberId,
  reservations,
  shopId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder/calculateCheckoutCashbackDeductionLimit`,
    data: {
      memberId,
      reservations,
    },
  })
}

export const FindAppointmentBatchOrder = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/appointmentBatchOrder/${id}`,
  })
}

// appointmentOrder V2

export const CreateAppointmentOrderV2 = async ({
  shopId,
  reservations,
  userType,
  memberId,
  userName,
  userPhone,
  userComment,
  adminComment,
  peopleCount,
  chargeType,
  paymentCode,
  paymentPrice,
  paymentComment,
  cashbackDeductionAmount,
  invoiceInfo,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder/v2`,
    data: {
      reservations,
      userType,
      memberId,
      userName,
      userPhone,
      userComment,
      adminComment,
      peopleCount,
      chargeType,
      paymentCode,
      paymentPrice,
      paymentComment,
      cashbackDeductionAmount,
      invoiceInfo,
    },
  })
}

export const CreateBatchAppointmentOrderV2 = async ({
  shopId,
  orders,
  userType,
  memberId,
  userName,
  userPhone,
  userComment,
  adminComment,
  chargeType,
  paymentCode,
  paymentPrice,
  paymentComment,
  paymentEmail,
  cashbackDeductionAmount,
  invoiceInfo,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder/batchCreate/v2`,
    data: {
      orders,
      userType,
      memberId,
      userName,
      userPhone,
      userComment,
      adminComment,
      chargeType,
      paymentCode,
      paymentPrice,
      paymentComment,
      paymentEmail,
      cashbackDeductionAmount,
      invoiceInfo,
    },
  })
}

export const AppointmentOrderCalculateDetailV2 = async ({
  shopId,
  memberId,
  orders,
  cashbackDeductionAmount,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder/calculateDetail/v2`,
    data: {
      memberId,
      orders,
      cashbackDeductionAmount,
    },
  })
}

export const AppointmentBatchOrderPaymentRefund = async ({
  shopId,
  id,
  refundType,
  refundTypeInfo,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentBatchOrder/${id}/paymentRefund`,
    data: {
      refundType,
      refundTypeInfo,
    },
  })
}

export const AppointmentCalculateItemizedDetails = async ({
  shopId,
  memberId,
  orders,
  cashbackDeductionAmount,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/appointmentOrder/calculateItemizedDetails`,
    data: {
      memberId,
      orders,
      cashbackDeductionAmount,
    },
  })
}
