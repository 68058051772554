import { admin, admin2 } from '@/api/instance'
// import ls from 'local-storage'

//= > 創建使用者
export const CreateUser = async ({
  name,
  email,
  password,
  role,
  shops,
}) => {
  const res = await admin({
    method: 'post',
    url: '/user',
    data: {
      name,
      email,
      password,
      role,
      shops,
    },
  })

  return res.data
}

//= > 刪除使用者
export const DeleteUser = async ({
  id,
}) => {
  const res = await admin({
    method: 'delete',
    url: `/user/${id}`,
  })

  return res.data
}

//= > 更新使用者資料
export const UpdateUser = async ({
  id,
  name,
  role,
}) => {
  const res = await admin({
    method: 'put',
    url: `/user/${id}`,
    data: {
      name,
      role,
    },
  })

  return res.data
}

//= > 更新使用者密碼
export const UpdateUserPassword = async ({
  id,
  password,
}) => {
  const res = await admin({
    method: 'put',
    url: `/user/${id}/password`,
    data: {
      password,
    },
  })

  return res.data
}

//= > 帳號設定 > 更新密碼
export const UpdateMePassword = async ({
  password,
}) => {
  return await admin2({
    method: 'put',
    url: '/user/updatePassword',
    data: {
      password,
    },
  })
}

//= > 更新使用者管理店家
export const UpdateUserShops = async ({
  id,
  shops,
}) => {
  const res = await admin({
    method: 'put',
    url: `/user/${id}/shops`,
    data: {
      shops,
    },
  })

  return res.data
}

//= > 取得使用者
export const GetUser = async ({
  start,
  limit,
  name,
  role,
}) => {
  const res = await admin({
    method: 'get',
    url: '/user',
    params: {
      start,
      limit,
      name,
      role,
    },
  })

  return res.data
}

//= > 取得指定使用者
export const FindUser = async ({ id }) => {
  const res = await admin({
    method: 'get',
    url: `/user/${id}`,
  })

  return res.data
}

//= > 取得總使用者數量
export const GetUsersCount = async ({
  role,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: '/user/count',
    params: {
      role,
      name,
    },
  })

  return res.data.count
}

//= > 取得當前登入使用者資料
export const GetMe = async () => {
  const res = await admin({
    method: 'get',
    url: '/user/me',
  })

  return res.data
}

//= > 取得當前登入使用者權限
export const GetMeFeature = async () => {
  const res = await admin({
    method: 'get',
    url: '/user/me/feature',
  })

  return res.data
}

export const LineNotify = async ({ redirectURL }) => {
  const res = await admin({
    method: 'get',
    url: '/user/me/lineNotifyOauthURL',
    params: {
      redirectURL,
    },
  })

  return res.data
}

export const FindMeLineNotify = async () => {
  const res = await admin({
    method: 'get',
    url: '/user/me/lineNotify',
  })

  return res.data
}

//= > 取得自訂義身份清單
export const FindCustomRole = async () => {
  const res = await admin({
    method: 'get',
    url: '/user/customRole',
  })

  return res.data
}

export const UserNotificationGetMeBindings = async ({
  orgId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${orgId}/userNotification/getMeBindings`,
  })
}

export const UpdateUserNotificationBindEmail = async ({
  orgId,
  email,
  enabled,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${orgId}/userNotification/bindEmail`,
    data: {
      email,
      enabled,
    },
  })
}

export const UserNotificationGetLineLoginAuthorizationUrl = async ({
  orgId,
  clientRedirectUri,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${orgId}/userNotification/getLineLoginAuthorizationUrl`,
    data: {
      clientRedirectUri,
    },
  })
}

export const UserNotificationUpdateLineBinding = async ({
  orgId,
  enabled,
  allowNotifyEvents,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${orgId}/userNotification/updateLineBinding`,
    data: {
      enabled,
      allowNotifyEvents,
    },
  })
}

// unbindLine
export const UserNotificationUnbindLine = async ({
  orgId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${orgId}/userNotification/unbindLine`,
  })
}
